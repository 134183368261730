<!--
 * @Description: 感兴趣模块
 * @Author: zhang zhen
 * @Date: 2023-02-14 21:47:31
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-07-20 17:28:44
 * @FilePath: /page-sass/src/components/tools/interestedCard.vue
-->
<template>
  <div class="interestedCard">
    <div class="interestedCard-action">
      <span class="title">为您推荐</span>
      <a-button type="link" class="exchangeBtn">
        <img src="~@/assets/reload.png" alt="">
        <span>换一批</span>
      </a-button>
    </div>
    <div class="interestedCard-cardBox">
      <div class="interestedCard-cardBox-item" v-for="i in 6" :key="i">
        <div class="img-cover">
          <img
            src="http://1.117.164.181/banner/64682d00e4b0be4bfea7da7a.png"
            alt="商品图片"
            class="top-img"
          />
        </div>
        
        
        <div class="goodName">包装设计系列产品礼盒纸箱</div>
        <h3 class="price">¥800</h3>
        <!-- <a-button type="primary" class="exchangeBtn" @click="handleSwitchLink">详情</a-button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'interestedCard',
  data() {
    return {}
  },
  methods: {
    handleSwitchLink() {
        this.$router.push('/productInfo')
    }
  },
}
</script>

<style lang="less" scoped>
.interestedCard {
  margin-top: 20px;
  &-action {
    font-size: 15px;
    color: #605f5f;
    margin-bottom: 24px;
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EFEFEF;
    position: relative;
    padding-left: 13px;
    &:before {
      background: #FF6E2D;
      display: inline-block;
      content: '';
      width: 5px;
      height: 16px;
      margin-right: 8px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000;
      margin-right: 10px;
    }
  }
  .exchangeBtn {
    padding-right: 0;
    img {
      width: 14px;
      margin-right: 5px;
    }
  }
  &-cardBox {
    background: #fff;
    border-radius: 8px;
    .flexLayout();
    flex-wrap: wrap;
    justify-content: flex-start;
    grid-gap: 24px 24px;
    &-item {
      border-radius: 8px;
      width: 208px;
      cursor: pointer;
      // border: 1px solid #f2f2f2;
      background: #fff;
      text-align: center;
    //   cursor: pointer;
      padding-bottom: 8px;
      margin-bottom: 20px;
      // &:hover {
      //   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      // }
      .top-img {
        width: 208px;
        height: 156px;
        border-radius: 8px;
      }
      .img-cover {
        margin-bottom: 8px;
        border-radius: 8px;
        position: relative;
        &::after {
          display: block;
          content: '';
          width: 208px;
          height: 156px;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 8px;
          background: transparent;
          transition: background 300ms cubic-bezier(0.89, 0.11, 0, 0.06);
        }
        &:hover {
          &::after {
            background: linear-gradient(179.82deg, rgba(0, 0, 0, 0) 0.15%, rgba(0, 0, 0, 0.5) 99.85%);
          }
        }
      }
      .price {
        font-size: 18px;
        color: #FF6E2D;
        padding: 0 4px;
        font-weight: bold;
        margin-bottom: 0;
        text-align: left
      }
      .goodName {
        .text-emphasis();
        font-size: 14px;
        padding: 0 4px;
        color: #222;
        margin-bottom: 4px;
        text-align: left
      }
      .saleData {
        .flexLayout();
        padding: 0 15px 10px;
        .evaluation {
          font-size: 13px;
          color: #f48807;
          font-weight: bold;
        }
        .saleInfo {
          color: #999;
          span {
            &:last-of-type {
              margin-left: 15px;
            }
          }
        }
      }
      .exchangeBtn {
        margin: 0;
      }
    }
  }
}
</style>
